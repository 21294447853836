require('jquery.cookie')


$(document).ready(function() {

  $('.cookies button').click(function(e){
      $.cookie('operacija_grad_cookie_consented', 'true', { path: '/', expires: 365 });
      $('.cookies').fadeOut("slow");
    });
  $('.mobile-app button').click(function(e){
      $.cookie('mobile_cookie_closed', 'true', { path: '/', expires: 365 });
      $('.mobile-app').fadeOut("slow");
    });

    // hamburger icon
    var hamburger = document.querySelector(".hamburger");
    var body = document.querySelector("body");
    hamburger.addEventListener("click", function() {
      hamburger.classList.toggle("is-active");
      body.classList.toggle('navigation_open')
    });

    // search
    $('.search_box').on('click', function(e){
      $('.search').show();
      $('.search').find('input').focus();
      $('body').addClass('search_open');
    });

    $('.close').on('click', function(){
      $('.search').fadeOut();
      $('body').removeClass('search_open');
    });
});
